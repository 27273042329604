import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

// Define meta data for each district
const districtData = {
    ariyalur: {
        title: "Ariyalur | Cement City of Tamil Nadu | TravelEasy-Make easy with us",
        description: "Ariyalur is known for its rich history and cultural heritage.",
        keywords: "ariyalur, tamil nadu, tourism, traveleasy, famous places in ariyalur, best places in ariyalur, tourist place in ariyalur",
        url: "https://traveleasy.co.in/destination/ariyalur",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/gangaikonda-cholapuram-1653462626_d1ad072dfb78c46813de.webp"
    },
    chengalpattu: {
        title: "Chengalpattu | Gateway to Chennai | TravelEasy-Make easy with us",
        description: "Chengalpattu is a Journey Through Spirituality, History, and Natural Beauty",
        keywords: "chengalpattu, gateway to chennai, tamil nadu, tourism, traveleasy, famous places in chengalpattu, best places in chengalpattu, tourist place in chengalpattu",
        url: "https://traveleasy.co.in/destination/chengalpattu",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Chengalpattu.jpg"
    },
    chennai: {
        title: "Chennai | Capital City | TravelEasy-Make easy with us",
        description: "Chennai is often referred to as the Detroit of India because of its thriving automobile sector.",
        keywords: "chennai, capital city, tamil nadu, tourism, traveleasy, famous places in chennai, best places in chennai, tourist place in chennai",
        url: "https://traveleasy.co.in/destination/chennai",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/1-marina-beach-city-hero.jpeg"
    },
    coimbatore: {
        title: "Coimbatore | Manchester of South India | TravelEasy-Make easy with us",
        description: "Coimbatore is referred to as the “Manchester of South India,” is a dynamic city.",
        keywords: "coimbatore, tamil nadu, tourism, traveleasy, manchester of south india, famous places in coimbatore, best places in coimbatore, tourist place in coimbatore",
        url: "https://traveleasy.co.in/destination/coimbatore",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/CBE.webp"
    },
    cuddalore: {
        title: "Cuddalore | Spice City | TravelEasy-Make easy with us",
        description: "Cuddalore, located in the southeastern part of Tamil Nadu, is a coastal city",
        keywords: "cuddalore, tamil nadu, tourism, traveleasy, famous places in cuddalore, best places in cuddalore, tourist place in cuddalore",
        url: "https://traveleasy.co.in/destination/cuddalore",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/pichavaram+baoting.jpg"
    },
    dharmapuri: {
        title: "Dharmapuri | Land of Tamarind | TravelEasy-Make easy with us",
        description: "Dharmapuri district located in the northwestern part of Tamil Nadu.",
        keywords: "dharmapuri, land of tamarind, tamil nadu, tourism, traveleasy, famous places in dharmapuri, best places in dharmapuri, tourist place in dharmapuri",
        url: "https://traveleasy.co.in/destination/dharmapuri",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Dharmapuri.webp"
    },
    dindigal: {
        title: "Dindigal | Lock City | TravelEasy-Make easy with us",
        description: "dindigal is popularly known as the 'Lock City' or 'City of Locks' due to its historical significance in the production of high-quality locks.",
        keywords: "dindigal, lock city, tamil nadu, tourism, traveleasy, famous places in dindigal, best places in dindigal, tourist place in dindigal, kodaikanal, kodaikanal places, kodaikanal spots, hill station in tamilnadu",
        url: "https://traveleasy.co.in/destination/dindigal",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/dindupalani.jpg"
    },
    erode: {
        title: "Erode | Textile City | Handloom City | TravelEasy-Make easy with us",
        description: "Erode is known as the 'Textile City' or 'Handloom City' due to its thriving textile and handloom industries.",
        keywords: "erode, textile city, handloom city, tamil nadu, tourism, traveleasy, famous places in erode, best places in erode, tourist place in erode",
        url: "https://traveleasy.co.in/destination/erode",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Erode.webp"
    },
    kallakurichi: {
        title: "Kallakurichi | Sugar Town | TravelEasy-Make easy with us",
        description: "kallakurichi is popularly known as the 'Sugar Town' due to its prominence in the sugarcane industry.",
        keywords: "kallakurichi, sugar town, tamil nadu, tourism, traveleasy, famous places in kallakurichi, best places in kallakurichi, tourist place in kallakurichi",
        url: "https://traveleasy.co.in/destination/kallakurichi",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Kallakurichi.webp"
    },
    kanchipuram: {
        title: "Kanchipuram | City of a Thousand Temples | TravelEasy-Make easy with us",
        description: "Kanchipuram is one of the most prominent ancient cities in India, located in the Indian state of Tamil Nadu.",
        keywords: "kanchipuram, famous for temples, thousand temples, tamil nadu, tourism, traveleasy, famous places in kanchipuram, best places in kanchipuram, tourist place in kanchipuram",
        url: "https://traveleasy.co.in/destination/kanchipuram",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/kancheepuram-1656094320_3f77ec88eada40ee1f36.webp"
    },
    kanniyakumari: {
        title: "Kanniyakumari | Land's End | Cape Comorin | TravelEasy-Make easy with us",
        description: "kanniyakumari is the southernmost point of mainland India, located in the state of Tamil Nadu",
        keywords: "kanniyakumari, land's End, cape comorin, tamil nadu, tourism, traveleasy, famous places in chengalpattu, best places in kanniyakumari, tourist place in kanniyakumari",
        url: "https://traveleasy.co.in/destination/kanniyakumari",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/kanyakumari-1656091428_cf69d9a9dbec82018046+1.webp"
    },
    karur: {
        title: "Karur | Textile Town | Textile Hub | TravelEasy-Make easy with us",
        description: "Karur is city in the Indian state of Tamil Nadu, is popularly known as the 'Textile Town' or 'Textile Hub' of Tamil Nadu",
        keywords: "karur, capital city, tamil nadu, tourism, traveleasy, famous places in karur, best places in karur, tourist place in karur",
        url: "https://traveleasy.co.in/destination/karur",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/KARUR.jpg"
    },
    krishnagiri: {
        title: "Krishnagiri | Land of Mangoes | TravelEasy-Make easy with us",
        description: "Krishnagiri is popularly known as the 'Land of Mangoes' due to the abundance of mango cultivation in the region.",
        keywords: "krishnagiri, tamil nadu, tourism, traveleasy, famous places in krishnagiri, best places in krishnagiri, tourist place in krishnagiri",
        url: "https://traveleasy.co.in/destination/krishnagiri",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/KRISHNAGIRI.jpg"
    },
    madurai: {
        title: "Madurai | Temple City | TravelEasy-Make easy with us",
        description: "Madurai is famous for its historical Meenakshi Temple and vibrant cultural heritage.",
        keywords: "madurai, temple city, tamil nadu, tourism, traveleasy, famous places in madurai, best places in madurai, tourist place in madurai",
        url: "https://traveleasy.co.in/destination/madurai",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/madurai-1653924923_1dd348c18c11422ee10f.webp"
    },
    mayiladuthurai: {
        title: "Mayiladuthurai | Temple Town | TravelEasy-Make easy with us",
        description: "Mayiladuthurai, popularly known as the Temple Town, is a historic city in Tamil Nadu renowned for its ancient temples, cultural heritage, and proximity to the Kaveri river.",
        keywords: "mayiladuthurai, temple town, tamil nadu, tourism, traveleasy, famous places in mayiladuthurai, best places in mayiladuthurai, tourist place in mayiladuthurai, mayuram, kaveri river, famous temples, cultural heritage, pilgrimage",
        url: "https://traveleasy.co.in/destination/mayiladuthurai",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/mayiladuthurai.webp"
    },
    nagapattinam: {
        title: "Nagapattinam | Rice Bowl of Tamil Nadu | TravelEasy-Make easy with us",
        description: "nagapattinam is popularly known as the 'Rice Bowl of Tamil Nadu' because of its fertile lands and extensive paddy fields",
        keywords: "nagapattinam, rice bowl of tamil nadu, tamil nadu, tourism, traveleasy, famous places in nagapattinam, best places in nagapattinam, tourist place in nagapattinam",
        url: "https://traveleasy.co.in/destination/nagapattinam",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/1-marina-beach-city-hero.jpeg"
    },
    namakkal: {
        title: "Namakkal | Egg City of India | TravelEasy-Make easy with us",
        description: "namakkal is popularly known as the 'Egg City of India' due to its significant contribution to the poultry industry, especially egg production",
        keywords: "namakkal, Egg City of India, tamil nadu, tourism, traveleasy, famous places in namakkal, best places in namakkal, tourist place in namakkal",
        url: "https://traveleasy.co.in/destination/namakkal",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Namakkal.jpg"
    },
    nilgiris: {
        title: "Nilgiris | Blue Mountains | TravelEasy-Make easy with us",
        description: "nilgiris is popularly known as the 'Blue Mountains'. This name is derived from the bluish hue of the mountains.",
        keywords: "nilgiris, Blue Mountains, tamil nadu, tourism, traveleasy, famous places in nilgiris, best places in nilgiris, tourist place in nilgiris, ooty, ooty best places to visit, places in ooty",
        url: "https://traveleasy.co.in/destination/nilgiris",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Nilgiri.jpg"
    },
    perambalur: {
        title: "Perambalur | Limestone District | TravelEasy-Make easy with us",
        description: "Perambalur is popularly known as the 'Limestone District' because of its rich limestone deposits.",
        keywords: "perambalur, Limestone District, tamil nadu, tourism, traveleasy, famous places in perambalur, best places in perambalur, tourist place in perambalur",
        url: "https://traveleasy.co.in/destination/perambalur",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Perambalur.jpg"
    },
    pudukkottai: {
        title: "Pudukkottai | TravelEasy-Make easy with us",
        description: "Pudukkottai, a culturally rich town in Tamil Nadu, is known for its ancient temples, historical landmarks, and prehistoric sites like Sittannavasal Cave and Thirumayam Fort.",
        keywords: "pudukkottai, tamil nadu, tourism, traveleasy, land of temples, heritage town, sittannavasal cave, thirumayam fort, famous places in pudukkottai, best places in pudukkottai, tourist place in pudukkottai",
        url: "https://traveleasy.co.in/destination/pudukkottai",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/pudhugai.jpg"
    },
    ramanathapuram: {
        title: "Ramanathapuram | Ramnad | TravelEasy-Make easy with us",
        description: "Ramanathapuram is popularly known as 'Ramnad', which is a shortened version of its name.",
        keywords: "ramanathapuram, Ramnad, Rameswaram, tamil nadu, tourism, traveleasy, famous places in ramanathapuram, best places in ramanathapuram, tourist place in ramanathapuram",
        url: "https://traveleasy.co.in/destination/ramanathapuram",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Ramanathapuram.jpg"
    },
    ranipet: {
        title: "Ranipet | Industrial Hub of Tamil Nadu | TravelEasy-Make easy with us",
        description: "Ranipet is particularly renowned for its leather industries, which contribute significantly to both the local economy and exports.",
        keywords: "ranipet, Industrial Hub of Tamil Nadu, tamil nadu, tourism, traveleasy, famous places in ranipet, best places in ranipet, tourist place in ranipet",
        url: "https://traveleasy.co.in/destination/ranipet",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Ranipet.jpg"
    },
    salem: {
        title: "Salem | The Steel City of Tamil Nadu | Mango City | Weaving Hub | TravelEasy-Make easy with us",
        description: "Salem its thriving steel industry and the presence of Salem Steel Plant, a unit of SAIL.",
        keywords: "salem, tamil nadu, tourism, steel city of tamilnadu, mango city, traveleasy, famous places in salem, best places in salem, tourist place in salem",
        url: "https://traveleasy.co.in/destination/salem",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/salem.webp"
    },
    sivagangai: {
        title: "Sivagangai | Sivagangai palace | TravelEasy-Make easy with us",
        description: "Sivagangai is known for its historical landmarks, temples, and cultural heritage.",
        keywords: "sivagangai, Sivaganga Palace, tamil nadu, tourism, traveleasy, famous places in sivagangai, best places in sivagangai, tourist place in sivagangai",
        url: "https://traveleasy.co.in/destination/sivagangai",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Sivagangai.jpg"
    },
    tenkasi: {
        title: "Tenkasi | Kashi of South | TravelEasy-Make easy with us",
        description: "Tenkasi, popularly known as the Kashi of South, is famous for its rich spiritual heritage, ancient temples like Sree Kasi Viswanathar, and its proximity to Courtallam waterfalls.",
        keywords: "tenkasi, Kashi of South, tamil nadu, tourism, traveleasy, famous places in tenkasi, best places in tenkasi, tourist place in tenkasi",
        url: "https://traveleasy.co.in/destination/tenkasi",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Tenkasi.jpg"
    },
    thanjavur: {
        title: "Thanjavur | Rice Bowl of Tamil Nadu | TravelEasy-Make easy with us",
        description: "Thanjavur is also known for its historical significance, especially in the context of the Chola dynasty.",
        keywords: "thanjavur, Rice Bowl of Tamil Nadu, tamil nadu, tourism, traveleasy, famous places in thanjavur, best places in thanjavur, tourist place in thanjavur",
        url: "https://traveleasy.co.in/destination/thanjavur",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Thanjavur.jpg"
    },
    theni: {
        title: "Theni | Gateway to the Western Ghats | TravelEasy-Make easy with us",
        description: "Theni is a town in the southern part of Tamil Nadu, India, and it is popularly known as the 'Gateway to the Western Ghats'",
        keywords: "theni, gateway to the western ghats, tamil nadu, tourism, traveleasy, famous places in theni, best places in theni, tourist place in theni",
        url: "https://traveleasy.co.in/destination/theni",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Theni.jpg"
    },
    thirunelveli: {
        title: "Thirunelveli | Oxford of South India | TravelEasy-Make easy with us",
        description: "Tirunelveli is popularly known as the 'Oxford of South India' due to its historical significance in education, as well as the 'City of Temples' because of the many ancient temples it houses.",
        keywords: "thirunelveli, Oxford of South India, City of Temples, tamil nadu, tourism, traveleasy, famous places in thirunelveli, best places in thirunelveli, tourist place in thirunelveli",
        url: "https://traveleasy.co.in/destination/thirunelveli",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Thirunelveli.jpg"
    },
    thirupathur: {
        title: "Thirupathur | Land of Temples | TravelEasy-Make easy with us",
        description: "Thirupathur is popularly known as 'Thirupathur, the Land of Temples' due to its rich cultural and religious heritage",
        keywords: "thirupathur, Land of Temples, tamil nadu, tourism, traveleasy, famous places in thirupathur, best places in thirupathur, tourist place in thirupathur",
        url: "https://traveleasy.co.in/destination/thirupathur",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Tirupattur.jpg"
    },
    thiruppur: {
        title: "Thiruppur | Knitwear Capital of India | TravelEasy-Make easy with us",
        description: "Thiruppur is renowned for its vast textile industry, especially for the production of knitted garments.",
        keywords: "thiruppur, Knitwear Capital of India, tamil nadu, tourism, traveleasy, famous places in thiruppur, best places in thiruppur, tourist place in thiruppur",
        url: "https://traveleasy.co.in/destination/thiruppur",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/fulldistrictimage/thirupur.webp"
    },
    thiruvallur: {
        title: "Thiruvallur | The Land of Temples | TravelEasy-Make easy with us",
        description: "Thiruvallur due to its rich historical and cultural heritage, with many ancient temples scattered throughout the region.",
        keywords: "thiruvallur, The Land of Temples, tamil nadu, tourism, traveleasy, famous places in thiruvallur, best places in thiruvallur, tourist place in thiruvallur",
        url: "https://traveleasy.co.in/destination/thiruvallur",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Thiruvallur.webp"
    },
    thiruvannamalai: {
        title: "Thiruvannamalai | Spiritual Capital | TravelEasy-Make easy with us",
        description: "Thiruvannamalai is popularly known as the 'Spiritual Capital' of Tamil Nadu and is a significant pilgrimage destination in South India.",
        keywords: "thiruvannamalai, Spiritual Capital, tamil nadu, tourism, traveleasy, famous places in thiruvannamalai, best places in thiruvannamalai, tourist place in thiruvannamalai",
        url: "https://traveleasy.co.in/destination/thiruvannamalai",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Tiruvannamalai.jpg"
    },
    thiruvarur: {
        title: "Thiruvarur | Temple Town | TravelEasy-Make easy with us",
        description: "thiruvarur is a prominent center for religious and cultural activities in the region.",
        keywords: "thiruvarur, Temple Town, tamil nadu, tourism, traveleasy, famous places in thiruvarur, best places in thiruvarur, tourist place in thiruvarur",
        url: "https://traveleasy.co.in/destination/thiruvarur",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Thiruvarur.jpg"
    },
    trichy: {
        title: "Trichy | Rockfort City | TravelEasy-Make easy with us",
        description: "Trichy is popularly referred to as the 'Rockfort City' due to the famous Rockfort Temple perched on a massive rock.",
        keywords: "trichy, Rockfort City, tamil nadu, tourism, traveleasy, famous places in trichy, best places in trichy, tourist place in trichy",
        url: "https://traveleasy.co.in/destination/trichy",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Tiruchirappalli.jpeg"
    },
    tuticorin: {
        title: "Tuticorin | Pearl City | TravelEasy-Make easy with us",
        description: "Tuticorin is derived from the city's historical association with pearl fishing, as it has long been a center.",
        keywords: "tuticorin, Pearl City, tamil nadu, tourism, traveleasy, famous places in tuticorin, best places in tuticorin, tourist place in tuticorin",
        url: "https://traveleasy.co.in/destination/tuticorin",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Tuticorin.jpg"
    },
    vellore: {
        title: "Vellore | Fort City | TravelEasy-Make easy with us",
        description: "Vellore is popularly known as the 'Fort City' due to its historical fort, Vellore Fort, which is one of the largest",
        keywords: "vellore, Fort City, tamil nadu, tourism, traveleasy, famous places in vellore, best places in vellore, tourist place in vellore",
        url: "https://traveleasy.co.in/destination/vellore",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/vellore.webp"
    },
    vilupuram: {
        title: "Vilupuram | Gateway to South Tamil Nadu | TravelEasy-Make easy with us",
        description: "Vilupuram reflects its strategic location, as it serves as a key entry point to the southern regions ",
        keywords: "vilupuram, Gateway to South Tamil Nadu, tamil nadu, tourism, traveleasy, famous places in vilupuram, best places in vilupuram, tourist place in vilupuram",
        url: "https://traveleasy.co.in/destination/vilupuram",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/viluppuram.jpg"
    },
    virudhunagar: {
        title: "Virudhunagar | City of Pilgrim | City of Trade | TravelEasy-Make easy with us",
        description: "Virudhunagar is also often referred to as the 'Birthplace of the Freedom Struggle' due to the role it played during India's independence movement",
        keywords: "virudhunagar, City of Pilgrim, City of Trade, tamil nadu, tourism, traveleasy, famous places in virudhunagar, best places in virudhunagar, tourist place in virudhunagar",
        url: "https://traveleasy.co.in/destination/virudhunagar",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/virudhunagar.webp"
    },
    pondicherry: {
        title: "Pondicherry | The French Riviera of the East | TravelEasy-Make easy with us",
        description: "Pondicherry its colonial French architecture, beautiful beaches, and French influence, which is still evident in its culture, language, and lifestyle.",
        keywords: "pondicherry, The French Riviera of the East, tamil nadu, tourism, traveleasy, famous places in pondicherry, best places in pondicherry, tourist place in pondicherry, puducherry, french colony, beaches, sri aurobindo ashram",
        url: "https://traveleasy.co.in/destination/pondicherry",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/Districts/Pondy.jpg"
    }
};

const DistrictPage = () => {
    const { name } = useParams(); 
    console.log(name);
    
    const districtName = name || 'salem';
    const districtKey = typeof districtName === 'string' ? districtName.toLowerCase() : '';
    const meta = districtData[districtKey] || {
        title: "TravelEasy | Make Travel Easy with Us",
        description: "Travel guide for the travel lovers and those who interested to travel",
        keywords: "travel, traveleasy, tourism",
        url: "https://traveleasy.co.in/",
        image: "https://traveleasystorage.s3.eu-north-1.amazonaws.com/default-image.webp"
    };
    return (
        <>
            <Helmet>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description} />
                <meta name="keywords" content={meta.keywords} />
                <meta name="Title" content={meta.title} />
                <meta property="og:url" content={meta.url} />
                <meta property="og:title" content={meta.title} />
                <meta property="og:description" content={meta.description} />
                <meta property="og:site_name" content="TravelEasy" />
                <meta property="og:image" content={meta.image} />
                <meta property="og:type" content="website" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={meta.url} />
                <meta property="twitter:title" content={meta.title} />
                <meta property="twitter:description" content={meta.description} />
                <meta property="twitter:image" content={meta.image} />
                <link rel="canonical" href={meta.url} />
            </Helmet>
        </>
    );
};

export default DistrictPage;
